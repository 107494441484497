<template>
  <section :class="['box', classes]">
    <slot></slot>
  </section>
</template>

<script name="Box" setup lang="ts">
import { computed } from 'vue'

type BoxProps = {
  type?: 'round' | 'square' | 'none'
}

const props = withDefaults(defineProps<BoxProps>(), {
  type: 'none',
})

const classes = computed(() => {
  return {
    box__none: props.type === 'none',
    box__round: props.type === 'round',
    box__square: props.type === 'square',
  }
})
</script>

<style scoped>
.box {
  height: var(--search-box-box-height, 38px);
  background-color: var(--search-box-bg-color, rgb(246, 246, 246));
  /* 超出椭圆隐藏 */
  overflow: hidden;
}
.box__none {
  border-radius: 0;
  border: none;
}
.box__round {
  background-color: var(--search-box-bg-color, #fff);
  border-radius: 2000px;
  border-style: solid;
  border-color: var(--search-box-border-color, #222);
  border-width: var(--search-box-border-width, 1px);
}
.box__square {
  background-color: var(--box-bg-color, #fff);
  border-radius: 0;
  border-style: solid;
  border-color: var(--search-box-border-color, #222);
  border-width: var(--search-box-border-width, 1px);
}
</style>
