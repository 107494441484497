<template>
  <div class="tag-button">
    <span class="tag-button__text" @click="handleTagButtonClick">
      <slot></slot>
    </span>
    <Icon class="close-tag" name="sui_icon_close_12px_2" size="12px" color="#222222" @click="handleCloseTagClick" />
  </div>
</template>

<script name="TagButton" setup lang="ts">
import { Icon } from '@shein-aidc/icon-vue3'

type TagButtonEmits = {
  (e: 'closeTagClick'): void
  (e: 'tagClick'): void
}

const emit = defineEmits<TagButtonEmits>()

const handleCloseTagClick = () => {
  emit('closeTagClick')
}

const handleTagButtonClick = () => {
  emit('tagClick')
}
</script>

<style scoped>
.tag-button {
  display: inline-flex;
  align-items: center;
  color: rgb(102, 102, 102);
  /* padding: 0 10px; */
  background: #fff;
  border-radius: 2000px;
  padding-top: var(--tag-button-padding-top, 0);
  padding-bottom: var(--tag-button-padding-bottom, 0);
  padding-left: var(--tag-button-padding-left, 10px);
  padding-right: var(--tag-button-padding-right, 10px);
}
.tag-button__text {
  font-size: var(--tag-button-text-font-size, 14px);
  color: var(---sui_color_gray_dark2, #666);
  white-space: nowrap;
  margin-right: 4px;
}

.close-tag {
  position: relative;
}
.close-tag::after {
  --tap-increment: -3px;
  content: '';
  position: absolute;
  top: var(--tap-increment);
  left: var(--tap-increment);
  right: var(--tap-increment);
  bottom: var(--tap-increment);
}
</style>
