<template>
  <Box :type="boxType">
    <div :class="['active-search-box', showSearchButton ? 'active-search-button': '']">
      <div class="active-search-box__content">
        <div
          v-if="value && !isTagMode"
          class="active-search-box__text"
          @click="handleTextClick">
          {{ value }}
        </div>
        <div
          v-if="isTagMode"
          ref="tagWrapperRef"
          class="active-search-box__scroll-wrapper">
          <TagButton
            v-for="(item, index) in tagList"
            :key="item.filterId"
            class="tag-button__wrapper"
            @tagClick="handleTagClick(item, index)"
            @closeTagClick="handleCloseTagClick(item, index)"
          >
            {{ item.filterWord }}
          </TagButton>
        </div>
      </div>
      <div
        v-if="showClearIcon"
        class="active-search-box__clear"
        @click="handleClear">
        <ClearIcon />
      </div>
      <div
        v-if="showCameraIcon"
        class="active-search-box__camera"
        @click="handleCameraIconClick">
        <slot
          v-if="$slots.cameraIcon"
          name="cameraIcon"></slot>
        <CameraIcon v-else />
      </div>
      <div
        v-if="showSearchButton"
        :class="['active-search-box__button', searchButtonType === 'round' ? 'round-gap' : '']"
        @click="handleSearchButtonClick"
      >
        <SearchButton :type="searchButtonType" />
      </div>
    </div>
  </Box>
</template>

<script name="ActiveSearchBox" setup lang="ts">
import { ref, watchEffect } from 'vue'
import Box from '../Box'
import CameraIcon from '../CameraIcon'
import ClearIcon from '../ClearIcon'
import TagButton from '../TagButton'
import SearchButton from '../SearchButton'

const tagWrapperRef = ref<HTMLElement | null>(null)

type ActiveSearchBoxProps = {
  boxType?: 'round' | 'square' | 'none'
  searchButtonType?: 'round' | 'square'
  value?: string
  isTagMode?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tagList?: Array<any>
  showCameraIcon?: boolean
  showClearIcon?: boolean
  showSearchButton?: boolean
}

const props = withDefaults(defineProps<ActiveSearchBoxProps>(), {
  boxType: 'none',
  value: '',
  isTagMode: false,
  tagList: () => [],
  showCameraIcon: false,
  showClearIcon: true,
  showSearchButton: false,
})

type ActiveSearchBoxEmits = {
  (e: 'cameraIconClick'): void
  (e: 'clearClick'): void
  (e: 'searchButtonClick'): void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (e: 'tagClick', item: any, index: number): void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (e: 'closeTagClick', item: any, index: number): void
  (e: 'update:value', value: string): void
  (e: 'input', value: string): void
  (e: 'textClick', value: string): void
}

const emit = defineEmits<ActiveSearchBoxEmits>()

const handleClear = () => {
  emit('clearClick')
  emit('update:value', '')
  emit('input', '')
}

const handleCameraIconClick = () => {
  emit('cameraIconClick')
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleTagClick = (item: any, index: number) => {
  emit('tagClick', item, index)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleCloseTagClick = (item: any, index: number) => {
  emit('closeTagClick', item, index)
}

const handleTextClick = () => {
  emit('textClick', props.value)
}

const handleSearchButtonClick = () => {
  emit('searchButtonClick')
}

const scrollTagToEnd = () => {
  const scrollWrapper = tagWrapperRef.value
  if (scrollWrapper) {
    scrollWrapper.scrollLeft = scrollWrapper.scrollWidth
  }
}

watchEffect(() => {
  if (props.tagList.length) {
    scrollTagToEnd()
  }
})
</script>

<style scoped>
.active-search-box {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  padding-left: var(--active-search-box-padding-left, 10px);
  padding-right: 10px;
}
.active-search-box.active-search-button {
  padding-right: 0;
}

.active-search-box__content {
  flex: 1;
  height: 100%;
  display: grid;
  color: #959595;
  /* 隐藏滚动超出部分 */
  overflow: hidden;
}

.active-search-box__text {
  display: grid;
  align-items: center;
  height: 100%;
  /* text scroll */
  overflow-x: auto;
  white-space: nowrap;
  color: var(--active-search-default-text-color, #666);
}

.active-search-box__text::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}

.active-search-box__scroll-wrapper {
  display: flex;
  align-items: center;
  /* tag scroll */
  overflow-x: auto;
  padding-right: 14%;
}

.tag-button__wrapper {
  margin-left: var(--active-search-tag-button-margin-left, 10px);
  height: var(--active-search-tag-button-height, unset);
}
.tag-button__wrapper:first-child {
  margin-left: 0;
}

/* .tag-button__wrapper:last-child {
  margin-right: var(--active-search-last-tag-button-margin-right, 0);
} */

.active-search-box__scroll-wrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}

.active-search-box__clear {
  padding-left: 5px;
}

.active-search-box__camera {
  display: flex;
  padding-left: 5px;
}

.active-search-box__button {
  height: 100%;
}
.active-search-box__button.round-gap {
  padding: 2px;
}
</style>
