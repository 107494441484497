import type { DS_SearchBox } from '../../../types'

export const getKeywordsWithBff: DS_SearchBox.APIS_THIS['getKeywordsWithBff'] = async function (params: any, httpConfig: any) {
  const config = {
    url: '/product/search/v3/get_keywords',
    method: httpConfig?.method || 'GET',
    params,
    ...(httpConfig?.method === 'POST' ? null : {headers: {'Content-Type': 'multipart/form-data'}}),
    featureKey: httpConfig?.featureKey || '',
  }
  const result = await this.$schttp(config)

  return result.data
}

export const getAssociationWordsWithBff: DS_SearchBox.APIS_THIS['getAssociationWordsWithBff'] = async function (params: any) {
  const result = await this.$schttp<any>({
    url: '/product/association_words',
    params,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  return result.data
}

export const getMarketingCodeWithBff: DS_SearchBox.APIS_THIS['getMarketingCodeWithBff'] = async function (keyword: string) {
  const { data } = await this.$schttp<{info : any}>({
    url: `/product/get_products_by_keywords?keywords=${keyword}`,
    method: 'POST',
  })
  if (data.info.page_id) {
    try {
      const url = new URL(data.info.page_id)
      const hrefTarget = data.info.page_id.replace(url.origin, '')
      return {
        hrefTarget: hrefTarget,
        hrefType: hrefTarget ? 'webLink' : '',
      }
    } catch (e) {
      console.error('营销code error', e)
      return {
        hrefTarget: '',
        hrefType: '',
      }
    }
  } else {
    return {
      hrefTarget: '',
      hrefType: '',
    }
  }
}
