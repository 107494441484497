<template>
  <button :class="['search-button', type ? `search-button--${type}`: '']">
    <SearchIcon
      v-if="type !== 'text'"
      background-color="transparent"
      color="var(--search-box-search-icon-color, white)"
      size="var(--search-box-search-icon-size, 20px)"
    />
    <slot v-else></slot>
  </button>
</template>

<script name="SearchButton" setup lang="ts">
import SearchIcon from '../SearchIcon'

type SearchButtonProps = {
  type?: 'round' | 'square' | 'text'
}

withDefaults(defineProps<SearchButtonProps>(), {
  type: 'square',
})
</script>

<style scoped>
.search-button {
  /* display: grid;
  place-content: center; */
  border: none;
  /* padding: 7px 13px; */
  padding-top: var(--search-box-search-button-padding-top, 7px);
  padding-bottom: var(--search-box-search-button-padding-bottom, 7px);
  padding-left: var(--search-box-search-button-padding-left, 13px);
  padding-right: var(--search-box-search-button-padding-right, 13px);
  background-color: var(--search-box-search-button-color, #222);
  width: 100%;
  height: 100%;
}
.search-button--round {
  /* padding: 4px 12px; */
  padding-top: var(--search-box-search-button-round-padding-top, 4px);
  padding-bottom: var(--search-box-search-button-round-padding-bottom, 4px);
  padding-left: var(--search-box-search-button-round-padding-left, 12px);
  padding-right: var(--search-box-search-button-round-padding-right, 12px);

  border-radius: 2000px;
}

.search-button--text {
  background-color: transparent;
}
</style>
