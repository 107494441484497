<template>
  <SwiperSlide
    v-if="searchTerms.length"
    ref="swiperSlideRef"
    class="search-terms"
    :autoplay="3000"
    :transition-duration="300"
    :item-length="searchTerms.length"
    :touchable="false"
    :prevent-default="false"
    :loop="true"
    :vertical="true"
    :intersection-observer="true"
    @slideChangeTransitionEnd="handleTransitionEnd"
  >
    <SwiperSlideItem
      v-for="(item, index) in searchTerms"
      :key="item.word"
      class="search-terms__item"
      :index="index"
      stop-propagation
      @click.stop="handleSearchTermClick(item, index)"
    >
      <div class="search-term__wrapper">
        <Icon
          v-if="index < hotTermIndex"
          name="sui_icon_hot_12px"
          size="15px"
          color="#F86262"
          :style="'margin-right: 2px;'"
        />
        <div class="search-term__text" :style="searchTermStyle(item)">{{ item.word }}</div>
        <template v-if="showTermIcon">
          <Icon
            v-if="['trendword', 'trendstore'].includes(item.wordLabel?.labelType)"
            name="sui_icon_search_trends_16px"
            size="16px"
            color="#9462FF"
            class="search-term__trend-icon"
          />
          <span
            v-if="item.wordLabel?.labelType === 'trend'"
            class="search-term__up-icon"></span>
          <Icon
            v-if="item.wordLabel?.labelType === 'hot'"
            name="sui_icon_hot_12px"
            size="16px"
            color="#F86262" />
          <span
            v-if="item.wordLabel && item.wordLabel.labelFront && ['sold', 'like'].includes(item.wordLabel?.labelType)"
            class="search-term__info-icon"
          >
            {{ item.wordLabel.labelFront }}
          </span>
          <img
            v-if="item.wordLabel?.labelType === 'choiceword'"
            :src="item.wordLabel?.icon"
            class="search-term__choice-icon"
          />
        </template>
      </div>
    </SwiperSlideItem>
  </SwiperSlide>
  <div
    v-else
    class="empty-search-terms"
    @click="handleEmptySearchTermClick">
    {{ emptySearchTermsText }}
  </div>
</template>

<script name="SearchTerms" setup lang="ts">
import { Icon } from '@shein-aidc/icon-vue3'
import SwiperSlide from '../SwiperSlide/SwiperSlide.vue'
import SwiperSlideItem from '../SwiperSlide/SwiperSlideItem.vue'
import { SearchTerm } from './SearchTermType'
import { nextTick, ref, watchEffect, watch } from 'vue'

type SearchTermsProps = {
  searchTerms?: Partial<SearchTerm>[]
  emptySearchTermsText?: string,
  showTermIcon?: boolean,
  hotTermIndex?: number,
}

const props = withDefaults(defineProps<SearchTermsProps>(), {
  searchTerms: () => [],
  emptySearchTermsText: 'Search',
  showTermIcon: true,
  hotTermIndex: -1,
})

type SearchTermsEmits = {
  (e: 'searchTermClick', item: Partial<SearchTerm> | null, index: number): void
  (e: 'searchTermTransitionEnd', item: Partial<SearchTerm>, index: number): void
}

const swiperSlideRef = ref<InstanceType<typeof SwiperSlide>>()

const emit = defineEmits<SearchTermsEmits>()

const handleSearchTermClick = (item: Partial<SearchTerm>, index: number) => {
  emit('searchTermClick', item, index)
}

const handleEmptySearchTermClick = () => {
  emit('searchTermClick', null, -1)
}

const searchTermStyle = (item: Partial<SearchTerm>) => {
  const isTrendType = item.wordLabel && ['trendword', 'trendstore'].includes(item.wordLabel?.labelType)

  if (isTrendType) {
    return {
      color: '#9462FF',
    }
  }

  return {
    color: 'var(--search-term-text-color, #666)',
  }
}

const handleTransitionEnd = (index: number) => {
  emit('searchTermTransitionEnd', props.searchTerms[index], index)
}

const updateSwiper = () => {
  nextTick(() => {
    if (props.searchTerms.length <= 1) {
      swiperSlideRef.value?.onAutoplayStop?.()
    }
    swiperSlideRef.value?.onUpdate?.()
  })
}

watchEffect(() => {
  updateSwiper()
})

defineExpose({
  updateSwiper,
})

watch(() => props.searchTerms, (newVal, oldVal) => {
  updateSwiper()
  console.log('swiper data', JSON.parse(JSON.stringify({
    newVal,
    oldVal,
  })))
}, { deep: true })

</script>

<style scoped>
.search-terms {
  /* height: 100%; */
  height: var(--search-box-box-height, 38px);
}
.search-terms__item {
  height: 100%;
}

/* 轮播搜索词样式 */
.search-term__wrapper {
  height: 100%;
  display: flex;
  align-items: center;
}
.search-term__text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.search-term__up-icon {
  min-width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-size: contain;
  transform-origin: center;
  transform: scale(1.25);
  background-image: url('https://img.shein.com/images3/2023/10/27/f5/1698394226527e3792513f865e0b2d6124c9e7a646.png');
}
.search-term__info-icon {
  font-size: 12px;
  color: #999;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
}
.search-term__choice-icon{
  height: 12px;
  margin-left: 2px;
}

/* 空搜索词样式 */
.empty-search-terms {
  height: 100%;
  display: grid;
  align-items: center;
  color: #959595;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
