<template>
  <Icon name="sui_icon_search_12px" :style="style" :size="size" :color="color" />
</template>

<script name="SearchIcon" setup lang="ts">
import { Icon } from '@shein-aidc/icon-vue3'
import { computed } from 'vue'

type SearchIconProps = {
  backgroundColor?: string
  color?: string
  size?: string
}

const props = withDefaults(defineProps<SearchIconProps>(), {
  backgroundColor: 'rgba(0, 0, 0, 0)',
  color: 'var(--sui-icon-search-color, #959595)',
  size: 'var(--sui-icon-search-size, 18px)',
})

const style = computed(() => ({
  backgroundColor: props.backgroundColor,
}))
</script>
